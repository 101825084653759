export const bishop_b = `https://raw.githubusercontent.com/FrontendCodingYT/coding-projects/master/reactjs-projects/chess-app/public/assets/images/bishop_b.png`
export const bishop_w = `https://raw.githubusercontent.com/FrontendCodingYT/coding-projects/master/reactjs-projects/chess-app/public/assets/images/bishop_w.png`
export const king_b = `https://raw.githubusercontent.com/FrontendCodingYT/coding-projects/master/reactjs-projects/chess-app/public/assets/images/king_b.png`
export const king_w = `https://raw.githubusercontent.com/FrontendCodingYT/coding-projects/master/reactjs-projects/chess-app/public/assets/images/king_w.png`
export const knight_b = `https://raw.githubusercontent.com/FrontendCodingYT/coding-projects/master/reactjs-projects/chess-app/public/assets/images/knight_b.png`
export const knight_w = `https://raw.githubusercontent.com/FrontendCodingYT/coding-projects/master/reactjs-projects/chess-app/public/assets/images/knight_w.png`
export const pawn_b = `https://raw.githubusercontent.com/FrontendCodingYT/coding-projects/master/reactjs-projects/chess-app/public/assets/images/pawn_b.png`
export const pawn_w = `https://raw.githubusercontent.com/FrontendCodingYT/coding-projects/master/reactjs-projects/chess-app/public/assets/images/pawn_w.png`
export const queen_b = `https://raw.githubusercontent.com/FrontendCodingYT/coding-projects/master/reactjs-projects/chess-app/public/assets/images/queen_b.png`
export const queen_w = `https://raw.githubusercontent.com/FrontendCodingYT/coding-projects/master/reactjs-projects/chess-app/public/assets/images/queen_w.png`
export const rook_b = `https://raw.githubusercontent.com/FrontendCodingYT/coding-projects/master/reactjs-projects/chess-app/public/assets/images/rook_b.png`
export const rook_w = `https://raw.githubusercontent.com/FrontendCodingYT/coding-projects/master/reactjs-projects/chess-app/public/assets/images/rook_w.png`
